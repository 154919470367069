import React from "react";
import { Box, Grid, Hidden, Button } from "@material-ui/core";
import WrapTypo from "../components/WrapTypo";
import { useStaticQuery, graphql, Link } from "gatsby";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import Img from "gatsby-image";
import Head from "../components/head";
import Header from "../components/Header_normal";
import Footer from "../components/footer";

const trcell = {
  height: 50,
  color: "#4C4C4C",
};

function Recruiting() {
  const data = useStaticQuery(graphql`
    query {
      handshake: file(relativePath: { eq: "handshake.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      form: file(relativePath: { eq: "form.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Head />
      <Header isBlack={true} />
      <Box maxWidth="800px" margin="auto">
        <Box width="95%" margin="auto" mt={8}>
          <Hidden smDown>
            <Box
              style={{
                textOrientation: "upright",
                writingMode: "vertical-rl",
                marginLeft: "auto",
              }}
            >
              <WrapTypo fw="700" fs="18px" lh="250%" ls="10px">
                同志の募集
              </WrapTypo>
            </Box>
          </Hidden>
          <Hidden mdUp>
            <Box
              style={{
                textOrientation: "upright",
                writingMode: "vertical-rl",
                margin: "auto",
              }}
            >
              <WrapTypo fw="700" fs="18px" lh="250%" ls="10px">
                同志の募集
              </WrapTypo>
            </Box>
          </Hidden>
          <Box mb={6}>
            <Grid
              container
              style={{ height: "90%" }}
              margin="auto"
              alignItems="center"
            >
              <Grid item xs={12} md={6}>
                <Box width="70%">
                  <Img fluid={data.handshake.childImageSharp.fluid} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Hidden xsDown>
                  <WrapTypo fw="170" fs="150%" color="#4C4C4C">
                    採用情報
                  </WrapTypo>
                  <Box margin="auto" mt={2}>
                    <WrapTypo fs="14px" lh="180%" margin="auto">
                      <table>
                        <tr style={trcell}>
                          <td>仕事内容 :　　　</td>
                          <td>現場作業員,施工管理 (性別は問いません。)</td>
                        </tr>
                        <tr style={trcell}>
                          <td>勤務形態 :　　</td>
                          <td>正社員</td>
                        </tr>
                        <tr style={trcell}>
                          <td>福利厚生：　　</td>
                          <td>
                            社会保険（健康保険・厚生年金・雇用保険・労災保険）
                          </td>
                        </tr>
                        <tr style={trcell}>
                          <td>休日 :　　</td>
                          <td>
                            年末年始・お盆・5月連休（GW）・その他会社指定日
                          </td>
                        </tr>
                        <tr style={trcell}>
                          <td>免許 :　　</td>
                          <td>普通免許</td>
                        </tr>
                      </table>
                    </WrapTypo>
                  </Box>
                </Hidden>
                <Hidden smUp>
                  <WrapTypo fs="15px" color="#4C4C4C">
                    採用情報
                  </WrapTypo>
                  <Box margin="auto" mt={2}>
                    <WrapTypo fs="14px" lh="180%" margin="auto">
                      <table>
                        <tr style={trcell}>
                          <td>仕事内容 :　　　</td>
                          <td>現場作業員,施工管理 (性別は問いません。)</td>
                        </tr>
                        <tr style={trcell}>
                          <td>勤務形態 :　　</td>
                          <td>正社員</td>
                        </tr>
                        <tr style={trcell}>
                          <td>福利厚生：　　</td>
                          <td>
                            社会保険（健康保険・厚生年金・雇用保険・労災保険）
                          </td>
                        </tr>
                        <tr style={trcell}>
                          <td>休日 :　　</td>
                          <td>
                            年末年始・お盆・5月連休（GW）・その他会社指定日
                          </td>
                        </tr>
                        <tr style={trcell}>
                          <td>免許 :　　</td>
                          <td>普通免許</td>
                        </tr>
                      </table>
                    </WrapTypo>
                  </Box>
                </Hidden>
              </Grid>
            </Grid>
          </Box>

          <Hidden xsDown>
            <Box justifyContent="flex-start" xsmt={8}>
              <WrapTypo fs="14px" lh="180%" margin="auto">
                先ずは下記フォームよりお気軽にお問い合わせください。
                ご質問は面接時に全てお答え致します。
              </WrapTypo>
            </Box>
          </Hidden>

          <Hidden smUp mt={8}>
            <Box justifyContent="flex-start">
              <WrapTypo fs="14px" lh="180%" margin="auto">
                先ずは下記フォームよりお気軽にお問い合わせください。
                ご質問は面接時に全てお答え致します。
              </WrapTypo>
            </Box>
          </Hidden>

          <Box mt={8} mb={8}>
            <Button
              variant="contained"
              color="#707070"
              disableElevation
              style={{ height: "60px", width: "100%", spacing: "20px" }}
            >
              <WrapTypo xsfs="80%" color="#4C4C4C">
                お問い合わせに移動する
              </WrapTypo>
            </Button>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default Recruiting;
